export const EXPO_PUBLIC_APP_VERSION = "6.183.0";
export const EXPO_PUBLIC_APP_NAME = "lawnlove-customer-web";
export const EXPO_PUBLIC_BRAND_SLUG = "lawnlove";
export const EXPO_PUBLIC_GOOGLE_API_KEY = "AIzaSyCtW4KAA_kr-LPbnH6EMPwOsnjvxqXTVuk";
export const EXPO_PUBLIC_CANCELLATION_SUPPORT_PHONE_NUMBER = "1-855-577-9573";
export const EXPO_PUBLIC_CANCELLATION_ZENDESK_WEB_CHANNEL_KEY = "da7e116a-6a38-4ff0-929f-2e24a1b6e1dd";
export const EXPO_PUBLIC_ZENDESK_WEB_CHANNEL_KEY = "5b31e0ab-e39e-4513-a013-f79c662d05ff";
export const EXPO_PUBLIC_SHARE_ON_NEXTDOOR_TIMEOUT_MINUTES = "5";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_3_CUT_MIN = "https://support.lawnlove.com/hc/en-us/articles/24319744075026--Customer-Customer-Policy-FAQs";
export const EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H = "https://support.lawnlove.com/hc/en-us/articles/24319744075026--Customer-Customer-Policy-FAQs";
export const SENTRY_ENVIRONMENT = "orange";
export const SENTRY_PROJECT_SLUG = "https://7a64fe4d24c140af9099be12c5818127@sentry.io/1367049";
export const EXPO_PUBLIC_ENV = "orange";
export const EXPO_PUBLIC_CUST_WEB_BASE_URL = "https://orange-my.lawnlove.com";
export const EXPO_PUBLIC_PARTNERS_BASE_URL = "https://orange-partners-api.lawnstarter.com";
export const EXPO_PUBLIC_API_GRAPHQL_ENDPOINT = "https://orange-api.lawnlove.com/graphql";
export const API_BASE_URL = "https://orange-api.lawnlove.com";
export const SIGNUP_BASE_URL = "https://orange-signup-web.lawnlove.com";
export const SEGMENT_KEY = "zby7S30gFG80iVKWtCUxMurdUBZwQvK5";
export const STRIPE_KEY = "pk_test_Gwo1qUvFZUQrufioqsESkESd";
export const LEGACY_CUST_WEB_BASE_URL = "https://orange-legacy-my.lawnlove.com";
export const FLAGSMITH_ENVIRONMENT_KEY = "jzEweWzucx28tn92aiNAgB";